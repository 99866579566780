/* Multi-Select Styles */
.content .multi-select .dropdown-heading {
    width: 200px;
    height: 26px;
    padding-top: -5px !important;
}

.content .container {
    padding-left: 10px !important;
}

.content .dropdown-content {
    padding: 0px !important;
}

.content .select-panel {
    background: #EEE;
}

.content ul {
    padding-left: 10px !important;
    margin-top: 5px !important;
    background: #EEE;
}

.content li {
    padding: 0px !important;
    margin: 0px;
}

.content label {
    padding: 1px !important;
    margin: 0px;
}

.content .item-renderer {
    margin-top: 1px !important;
    vertical-align: middle;
}

.content .dropdown-content {
    background: #EEE;
    width: max-content !important;
    font-weight: normal !important;
}

    .content .dropdown-content .options {
        padding-bottom: 10px;
        padding-top: 5px;
    }

    .content .dropdown-content .select-item {
        width: max-content !important;
        font-weight: normal !important;
        padding-right: 5px !important;
    }

.rmsc .select-item.selected {
    background: #eee !important;
}

.rmsc .dropdown-container {
    background: #eee !important;
}

.rmsc .dropdown-content .panel-content {
    background: #eee !important;
}

.content select option {
    background: #EEE;
}

.csv-selector {
    display: inline-block;
    background: #DDD;
    border-radius: 10px;
    margin: 10px;
    min-height: 25px;
}

    .csv-selector .label {
        padding: 10px;
    }

    .csv-selector .select {
        text-align: right;
        margin-left: 15px;
        margin-right: 0px;
        padding: 5px;
    }


.btn-small {
    font-size: 0.875em !important;
}

.ms-page-list {
    padding: 0px;
    margin-left: 8px;
    margin-top: 10px;
    margin-right: 40px;
    color: #479BD7;
}

    .ms-page-list .item {
        display: inline;
        border: black 1px solid;
        background-color: #EEE;
        margin-right: 5px;
        padding: 5px;
        border-radius: 15px;
        text-align: center;
    }

        .ms-page-list .item span {
            text-align: center;
        }

        .ms-page-list .item:hover {
            background-color: #fff;
            cursor: pointer;
        }

    .ms-page-list .active {
        background-color: #fff !important;
        font-weight: bold;
    }

.toggle-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle-switch-text {
    padding-right: 5px;
    font-size: 12px;
    position: relative;
    top: -3px;
}

.toggle-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 30px;
    height: 16px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
    display: inline-block;
}

    .toggle-switch-label:checked {
        background: green;
    }

    .toggle-switch-label .toggle-switch-button {
        content: '';
        position: absolute;
        top: 1.7px;
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 45px;
        transition: 0.2s;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.toggle-switch-label:active .toggle-switch-button {
    width: 30px;
}

.report-errors {
    font-size: 1rem;
    margin-top: 40px;
    padding: 10px;
}


.slick-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 4rem;
    margin-top: 3rem;
    background-color: white;
}

    .slick-container div {
        align-self: center;
        text-align: center;
        font-size: small;
        display: flex
    }

    .slick-container img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }

.slick-dots {
    gap: 0.5rem !important;
}

    .slick-dots li button:before {
        content: "" !important;
        color: rgb(0, 136, 255) !important;
    }

    .slick-dots li button {
        width: 0.5rem !important;
        height: 0.5rem !important;
        border-radius: 50px !important;
        border: 1px black solid;
        margin-top: 30px !important
    }

    .slick-dots .slick-active button {
        background-color: rgb(85, 0, 255) !important;
    }

ul {
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
}

.schoolContainer {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
}
/* Hide the Google Translate toolbar */
.goog-te-gadget {
    display: none !important;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.goog-te-gadget img {
    display: none !important;
}

body > .skiptranslate {
    display: none;
}

body {
    top: 0px !important;
}

iframe.goog-te-banner-frame {
    display: none !important;
}

div#goog-gt-tt {
    display: none !important;
}

iframe.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

/* Hide the Google Translate logo */
.goog-logo-link {
    display: none !important;
}

/* Hide the Google Translate spinner */
.goog-te-spinner-pos {
    display: none !important;
}

.debugging-message {
    background: #eee;
    border: 1px solid;
    border-radius: 15px;
    padding: 6px;
    z-index: 99;
}
