
/* Branding Styles */
main-header {
    display: flex;
}

main-section {
    flex: 3;
    padding-right: 10px;
}

aside {
    flex: 1;
    padding: 20px;
}

.rounded-border {
    border: 2px solid black;
    border-radius: 35px;
    padding-left: 30px;
}

.drop-shadow {
    box-shadow: 5px 5px 1px rgba(0.1, 0.1, 0.1, 0.1); /* Horizontal offset, vertical offset, blur radius, shadow color */
    padding-left: 20px; /* Padding inside the element */
    padding-right: 20px; /* Padding inside the element */
    background-color: white; /* Background color of the element */
}
