
/* Branding Styles */
#root {
    height: 100%;
    overflow: overlay;
}

.transiction {
    transition: 'width 0.5s ease-in -out',
}

.mobileMenu {
    background-color: #ffffff !important;
}

.dropdown-header-year {
    border: 2px solid #e2b84c !important;
    background-color: #ffffff !important;
    height: 40px;
    min-width: 130px;
    margin-right: 5px;
}

@media (max-width: 600px) {
    .dropdown-breadcrumb {
        margin-top: 0px;
        margin-right: 3px;
        width: auto !important;
        height: 20px !important;
        line-height: 15px !important;
        border: 1px solid #185A5C !important;
        background-color: #185A5C !important;
        padding: 0px 7px 0px 15px !important;
        color: white !important;
        color: white !important;
        background-image: none !important;
        font-size: 10px !important;
    }
}

@media (min-width: 601px) {
    .dropdown-breadcrumb {
        margin-top: 0px;
        margin-right: 3px;
        width: auto !important;
        height: 20px !important;
        line-height: 15px !important;
        border: 1px solid #185A5C !important;
        background-color: #185A5C !important;
        padding: 0px 7px 0px 15px !important;
        color: white !important;
        color: white !important;
        background-image: none !important;
        font-size: 12px !important;
    }
}




.dropdown-breadcrumb-select {
    position: relative;
    display: inline-block;
}

    .dropdown-breadcrumb-select::after {
        content: '';
        position: absolute;
        background: none;
        left: 0px;
        top: 7px;
        width: 0;
        height: 0;
        margin-left: 4px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid rgba(255,255,255,1);
        pointer-events: none;
    }


.container-dropdown-data {
    display: flex;
    align-items: center;
}

.definition-reference {
    font-weight: 400;
    color: #697286;
    font-size: 14px;
}


/* Ant Library CSS overwritten */
.report-layout-menu .ant-menu-submenu-open > div {
    background-color: #EDEBE9 !important;
    font-weight: bold;
    color: #212529;
}

.default-layout-menu .ant-menu-submenu-selected > div {
    background-color: #EDEBE9 !important;
    font-weight: bold;
}

.ant-menu, .ant-menu-submenu {
    font-weight: normal;
    height: unset;
}

.ant-menu-title-content {
    font-size: 14px;
    font-family: open;
    color: #212529;
    font-family: 'Open Sans','Helvetica Neue',sans-serif;
    white-space: normal !important;
}

.ant-menu-item {
    height: unset !important;
}

.ant-menu-submenu-title {
    height: unset !important;
}

.ant-menu-item:hover {
    font-weight: bold;
}

.ant-menu-item-selected {
    font-weight: bold;
}

/* End Ant Library */

.default-title-body {
    color: #333;
    font-size: 22px;
    line-height: 1.4285em;
    font-weight: 400;
    font-family: 'Open Sans','Helvetica Neue',sans-serif;
}

.body-container {
    background-color: white;
    height: 100%;
}

.title-search {
    margin-bottom: 0;
}

.icons-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.separator-home {
    height: 2px;
    background-color: #ccc;
    width: 800px;
}

.date-style {
    font-size: 28px;
}

.header-layout {
    display: flex;
    height: 80px;
    padding-right: 15px;
}

.logo-container {
    height: 80;
}

.header-layout .center-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .header-layout .center-header-container .header-title {
        color: white;
        margin: 0;
        font-size: 36px;
        flex: 1;
        text-align: center;
    }

.menu-container {
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    overflow-y: overlay;
}

.acessibility-container {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
}

.profile-container {
    display: flex !important;
    flex-direction: row;
    flex: 1;
}

    .profile-container .title-container {
        color: white;
        margin-right: 10px;
        margin-bottom: 0;
        align-items: center;
    }

    .profile-container .text-header {
        color: #e2e2e2;
        text-align: left;
    }

    .profile-container .flex {
        display: flex;
    }

    .profile-container .text-header .icon-container-header {
        background-color: #e2b84c;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 50px;
        color: black;
        font-size: 24px;
    }

        .profile-container .text-header .icon-container-header:hover {
            cursor: pointer;
        }

    .profile-container .text-header > h3 {
        margin: 0;
    }

    .profile-container .text-header .text-left {
        text-align: left;
    }

    .profile-container .text-header .text-right {
        text-align: right;
    }

.header-home-container {
    height: 20px;
}

    .header-home-container:hover {
        cursor: pointer;
    }

.translate-container {
    padding-top: 10px;
    color: black;
    text-align: right;
    float: right;
}

.card-container {
    display: flex;
    margin: 20px 0px 30px 0px;
    background-color: #f2f9f6;
    padding: 20px;
}

    .card-container .flex-row {
        flex-direction: row;
    }

    .card-container .flex-column {
        flex-direction: column;
    }

    .card-container .search-container {
        display: flex;
        justify-content: center;
    }

    .card-container .find-text {
        font-size: 20px;
        font-weight: bold;
        color: #2b6974;
        margin-bottom: 0;
    }

    .card-container .find-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }


.box-container {
    display: flex;
    width: 100%;
}

.card-container .box-container p {
    font-size: 18px;
    color: #636564;
    font-weight: 200;
}


.description-container-responsive {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dff1e7;
    padding: 30px 20px 0 20px;
}

    .description-container-responsive > p {
        font-size: 3em;
    }


.home-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

    .home-container .left-space {
        width: 100%;
        height: 100%;
        flex: 5;
        background-image: url('../../../../wwwroot/images/home/Home_Background.jpg');
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        padding: 40px;
        background-position: 0% 70%;
    }

        .home-container .left-space .title-container {
            background-color: white;
            width: fit-content;
            padding: 20px;
            opacity: 0.7;
        }

        .home-container .left-space p {
            font-size: 38px;
            line-height: 44px;
            color: black;
        }


.search-icon-container {
    border-radius: 50px;
    background-color: #e2b84c;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-input {
    border-radius: 50px;
    padding: 10px 15px 10px 15px;
    height: 40px;
    font-size: 16px;
    border: 2px solid #e2b84c;
}

.ant-select-selection-placeholder {
    color: #595959 !important;
}

.search-input::placeholder {
    color: white;
}


.home-container .right-space {
    width: 100%;
    height: 100%;
    flex: 4;
    background-color: #dff1e7;
    display: flex;
    flex-direction: column;
    overflow: overlay;
}

    .home-container .right-space .top {
        padding: 20px;
    }

    .home-container .right-space .bottom {
        flex: 1;
        background-color: #1f4d50;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

        .home-container .right-space .bottom .footer-description {
            color: #fff;
            text-align: right;
            font-weight: 200;
        }

        .home-container .right-space .bottom .info-links {
            display: flex;
            flex-direction: row;
            flex: 1;
        }

            .home-container .right-space .bottom .info-links .col-links {
                flex: 1;
                text-align: left;
                padding-left: 20px;
            }

                .home-container .right-space .bottom .info-links .col-links .title {
                    flex: 1;
                    text-align: left;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                }

                .home-container .right-space .bottom .info-links .col-links .link {
                    text-decoration: underline;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 200;
                }

                    .home-container .right-space .bottom .info-links .col-links .link:hover {
                        cursor: pointer;
                    }

.home-container .text-welcome-style {
    margin: 0;
    height: 30px;
    color: black;
    line-height: 30px;
    text-align: center;
    background: transparent;
}

.widget-container {
    background-color: #fff !important;
    display: flex !important;
    border: 2px solid #e2b84c !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    cursor: pointer !important;
    border-radius: 10px !important;
    width: 144px !important;
    min-width: 140px !important;
    margin-left: 20px !important;
    margin-top: 10px !important;
}


.mb-20 {
    margin-bottom: 20px !important;
}

.ml-40 {
    margin-left: 40px;
}

.ml-20 {
    margin-left: 20px;
}

.widget-container-smaller {
    flex: 1 !important;
    border: 2px solid #e2b84c !important;
    background-color: #ffffff !important;
    display: flex !important;
    border-radius: 10px !important;
    height: 120px;
    width: 143px;
    cursor: pointer !important;
    margin-left: 20px !important;
    margin-top: 10px !important;
    align-items: center !important;
    justify-content: center !important;
}

    .widget-container-smaller .name-space {
        padding: 10px;
        display: flex;
        align-items: center;
        text-align: center;
    }

        .widget-container-smaller .name-space h5 {
            text-align: center;
            font-size: 20px;
            font-weight: 200;
        }

    .widget-container-smaller .icon-space {
        background-color: #F8DCA3;
        padding: 10px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        display: flex;
        align-items: center;
    }

        .widget-container-smaller .icon-space .settings {
            color: #00595B;
            font-size: 28px;
        }

.widget-container .widget-title-container-large {
    height: 120px;
    width: 100%;
    font-size: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-container .widget-title-container {
    height: 40px;
    width: 100%;
    padding: 10px;
}

    .widget-container .widget-title-container .widget-title {
        margin-bottom: 0;
        text-align: center;
        font-size: 20px;
        font-weight: 200;
    }

    .widget-container .widget-title-container .widget-image {
        height: 100;
        display: flex;
        justify-content: center;
        align-items: center;
    }

.layout-definition {
    display: flex;
}

.footer-default {
    background-color: #185A5C;
    height: auto;
    width: 100%;
    align-items: center;
}

    .footer-default .info-container-default {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: auto;
        min-height: 30px;
        justify-content: space-between;
        color: #ffffff;
        font-weight: 400;
    }

        .footer-default .info-container-default .nav-footer-default {
            margin-right: 20px;
        }

        .footer-default .info-container-default .item {
            font-size: 14px;
        }

            .footer-default .info-container-default .item:hover {
                cursor: pointer;
            }

        .footer-default .info-container-default .copyright {
            font-size: 10px;
            margin: 0;
            display: flex;
            align-items: center;
            margin-left: 20px;
        }

.footer-default-mobile {
    background-color: #185A5C;
    width: 100%;
    align-items: center;
}

    .footer-default-mobile .info-container-default {
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        font-weight: 400;
    }

    .footer-default-mobile .info-container-default-mobile {
        align-items: center;
        height: auto;
        min-height: 30px;
        color: #ffffff;
        font-weight: 400;
    }

        .footer-default-mobile .info-container-default-mobile .item {
            font-size: 12px;
            text-align: center;
            margin-top: 0px;
        }

        .footer-default-mobile .info-container-default-mobile .row {
            margin-top: 0px !important;
            padding: 0px;
        }

        .footer-default-mobile .info-container-default-mobile .col {
            margin-top: 0px !important;
            padding: 0px;
        }

        .footer-default-mobile .info-container-default-mobile .item:hover {
            cursor: pointer;
        }

        .footer-default-mobile .info-container-default-mobile .copyright {
            font-size: 12px;
            margin-top: 5px;
            align-items: center;
            text-align: center;
        }

.slick-slide > div > div {
    font-size: 2rem;
    font-weight: bold;
}


.slick-prev,
.slick-next {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    height: 30px !important;
    width: 30px !important;
    border-radius: 22px;
    background-color: #185a5c !important;
}

.slick-display-item {
    line-height: 44px;
    border: 0;
    background-color: transparent;
    color: #3c6bdd;
    font-weight: bold;
}
