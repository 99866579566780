
.skip-to-content {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}

.skip-to-content:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

.report-header {
    background-color: #EEE !important;
}

.cleardiv {
    clear: both;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}


/*Margin*/
.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-17 {
    margin-right: 17px !important;
}

.m-r-20 {
    margin-right: 20px !important;
}

.m-r-40 {
    margin-right: 40px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-45 {
    margin-bottom: 45px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-t-0 {
    margin-top: 0px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-24 {
    margin-top: 24px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-t-28 {
    margin-top: 28px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-50 {
    margin-top: 50px !important;
}

.m-t-80 {
    margin-top: 80px !important;
}

.m-t-117 {
    margin-top: 118px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.m-l-30 {
    margin-left: 30px !important;
}

.m-l-40 {
    margin-left: 40px !important;
}

m-l-50 {
    margin-left: 50px !important;
}

/*Negative Margins*/
.m-l-3 {
    margin-left: -3px !important;
}

.m-l-6 {
    margin-left: -3px !important;
}

.m-l-n6 {
    margin-left: -6px !important;
}

.m-t-n2 {
    margin-top: -2px !important;
}

.m-t-n20 {
    margin-top: -20px !important;
}

.m-l-n40 {
    margin-left: -40px !important;
}

/*Padding*/
.p5 {
    padding: 5px !important;
}

.p10 {
    padding: 10px !important;
}

.p15 {
    padding: 15px !important;
}

.p20 {
    padding: 20px !important;
}

.p30 {
    padding: 30px !important;
}

.p40 {
    padding: 40px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.no-p {
    padding: 0px !important;
}

.p-t-5 {
    padding-top: 5px !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-r-5 {
    padding-right: 5px !important;
}

.p-r-40 {
    padding-right: 40px !important;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-b-150 {
    padding-bottom: 150px !important;
}


/* Font Awesome Formatting */
svg {
    margin-right: 5px;
}


/* Well Layout */
.general-well {
    background-color: #f5f5f5 !important;
    color: #666 !important;
}

.bordered-well {
    border: solid 1px;
    border-color: #000 !important;
    background-color: #f5f5f5 !important;
    color: #666 !important;
}

.bordered-well-shaded {
    border: solid 1px;
    border-color: #000 !important;
    background-color: #EEE !important;
    color: #666 !important;
}

.clear-well {
    border-color: #ddd !important;
    background-color: #fff !important;
    color: #666 !important;
}

.well {
    padding: 10px 10px;
    border-radius: 5px;
    margin: 20px 20px 20px 0;
}

/* Grid Layout */
.row {
    padding: 10px;
}

/* Tab Layout */
.tab-pane {
    border-bottom: solid 1px #EEE;
    border-left: solid 1px #EEE;
    border-right: solid 1px #EEE;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

/* Sort Option Styling */
.sortSeector-drag:active {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 1px 0 transparent;
    cursor: grab;
    border: 1px solid #c4c4c4;
    padding: 10px;
    margin: 5px;
    background-Color: #f9f9f9;
    border-Radius: 5px;
    margin-bottom: 8px !important;
}

.sortSeector {
    border: 1px solid #c4c4c4;
    padding: 10px;
    margin: 5px;
    background-Color: #f9f9f9;
    border-Radius: 5px;
    margin-bottom: 8px !important;
    /*cursor:pointer;*/
}

    .sortSeector:hover {
        border: 1px solid #c4c4c4;
        padding: 10px;
        margin: 5px;
        background-Color: #f0f0f0;
        border-Radius: 5px;
        margin-bottom: 8px !important;
        /*cursor: pointer;*/
    }

    .sortSeector:active {
        border: 1px solid #c4c4c4;
        padding: 10px;
        margin: 5px;
        background-Color: #f9f9f9;
        border-Radius: 5px;
        margin-bottom: 8px !important;
        cursor: pointer;
        /*box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 1px 0 rgba(0, 0, 0, -8.81);*/
    }

.public-site-section {
    min-height: 125px;
    margin-bottom: 15px;
}

.public-site-content {
}

.public-site-content-header {
    height: 50px;
}

.site-sections .ga {
    box-shadow: 2px 2px 10px #acacac;
    padding: 3px;
    cursor: pointer;
}

.site-sections .ga-container {
    height: 145px;
}

.site-sections .ga h3 {
    font-size: 1.00em;
    border-style: none;
    text-align: center;
}

.snapshotTextBox {
    text-align: center;
    font-size: 40px;
    padding-top: 40px;
}

.permissionsDivStyle {
    background-color: #ececec;
    color: black;
    border-radius: 5px;
    margin: 0px;
    padding: 5px;
}

.permissionsDivSelectedStyle {
    background-color: #5CB85C;
    color: white;
    border-radius: 5px;
    margin: 0px;
    padding: 5px;
}

    .permissionsDivSelectedStyle a {
        color: white;
    }

.icon-action:hover {
    cursor: pointer;
}

.excel-icon {
    color: darkgreen;
}

.pdf-icon {
    color: darkred;
}

.report-icon {
    max-height: 20px !important;
    max-width: 30px !important;
    max-width: 21px !important;
}

    .report-icon img {
        max-height: 20px !important;
        max-width: 30px !important;
        max-width: 21px !important;
    }

.icon-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}