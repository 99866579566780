    /* Branding Styles */
.help-title{
    font-size: 18px;
    font-weight: bold;
}

.contact-title{
    font-size: 14px;
    font-weight: bold;
    margin-left: 70px;
}


.info-container{
    margin-left: 0px;
    margin-top: 20px;
}

.info-container ul{
    list-style-type: disclosure-closed;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.mb-10{
    margin-bottom: 10px !important;
}

.margin-bottom-30{
    margin-bottom: 30px;
}

.disclaimer-container{
    font-size: 14px;
}

.subtitle{
    margin-bottom: 10px !important;
    font-size: 16px;
}

.description{
    margin-bottom: 0;
    font-size: 14px;
}

.disclaimer-container ul{
    font-size: 14px;
    list-style-type: inherit;
    font-weight: normal;
}

.title-cointainer-contact{
    display:flex;
}

.separator{
    height: 1px;
    width: 100%;
    background-color: #d8d8d8;
}

.faq-container{
    margin-bottom: 30px;
}

    .faq-container .title{
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
    }

    .faq-container .answer{
        font-size: 12px;
        font-weight: normal;
        color:#697286;
    }


    .faq-container .list-not-fill-circle{
        list-style-type: circle;
    }

    .faq-container .list-filled-circle{
        list-style-type: disc;
    }