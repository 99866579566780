.report-about {
    margin-top: 40px;
}

    .report-about ul {
        background: #FFF;
    }


.report-no-filter {
    margin: 40px;
    font-size: 19px;
    color: red;
}

.report-filters {
    background-color: #fff;
    border-radius: 15px;
    /*padding-left: 10px;*/
    padding-left: 0px;
    margin-bottom: 15px;
    font-size: 10pt;
    padding-top: 10px;
}

    .report-filters select {
        font-size: 10pt;
        line-height: 1.1;
    }


.report-filters-page3 {
    background-color: #fff;
    border-radius: 15px;
    padding-left: 10px;
    padding-top: 15px;
}

.report-results {
    font-size: 12px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.report-embedded {
    height: 100%;
    background-color: white;
    font-size: 12px !important;
    padding: 0px;
}

    .report-embedded .title {
        font-size: 16px;
        margin-bottom: 5px;
    }


    .report-embedded .subtitle {
        font-size: 22px;
        margin-bottom: 5px;
        color: #333;
        margin-bottom: 24px;
    }

    .report-embedded .description {
        font-size: 12px;
        margin-bottom: 5px;
        color: #333;
    }

    .report-embedded .reportdiv {
        border: 1px solid #aaa;
        border-radius: 15px;
        padding: 5px;
        margin: 5px;
    }

    .report-embedded .report-title {
        font-size: 15px !important;
        margin-bottom: 0px;
        margin-top: 10px;
        margin-left: 10px;
        color: #333;
    }

    .report-embedded .report-title-links .report-title-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }

        .report-embedded .report-title-links .report-title-container .flex-content {
            display: flex;
            align-items: center;
        }

            .report-embedded .report-title-links .report-title-container .flex-content .title-style {
                font-size: 18px;
                margin-bottom: 0;
            }

    .report-embedded .report-title-links .row {
        margin-bottom: 0px !important;
        padding: 0px;
        border-bottom: 1px #eee dashed
    }


    .report-embedded .report-title .report-content {
        display: flex;
        align-items: center;
    }


    .report-embedded .report-title .report-title-content {
        margin-bottom: 0;
        font-size: 20px;
    }

    .report-embedded .report-title-links {
        font-size: 15px !important;
        margin-bottom: 0px;
        margin-top: 10px;
        margin-left: 0;
        color: #333;
        padding-top: 10px;
    }

        .report-embedded .report-title-links .row {
            margin-bottom: 0px !important;
            padding: 0px;
            border-bottom: 1px #eee dashed
        }


    .report-embedded hr {
        border: 1px dotted #000;
        border-style: none none dotted;
        color: #000;
        background-color: #fff;
    }

    .report-embedded .nav-tabs {
        font-size: 14px;
        font-style: normal !important;
    }

    .report-embedded .tab-pane {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
    }

.spinner-content {
    display: flex;
    align-items: center;
}

    .spinner-content .loading-message {
        font-size: 8px;
    }

/* Tier Three Page Styles */
.report-page {
    overflow: auto;
    padding-bottom: 40px;
}

    .report-page .content {
        background-color: white;
        border: 1px solid #aaa;
        padding: 10px;
        overflow: hidden;
        border-radius: 15px;
    }

        .report-page .content select {
            width: 200px;
        }

        .report-page .content .filter {
            float: left;
            padding-right: 10px;
        }

/* Tier Three Page Styles */
.three-tier-closed {
    height: 0px !important;
}

.three-tier-open {
    height: 100% !important;
    width: 100% !important;
}

.three-tier {
    height: 100%;
    max-height: unset;
    overflow: auto;
    padding-bottom: 0px;
    z-index: 1000;
    transition: width 0.5s ease-in-out;
}

    .three-tier .title {
        font-size: 16px;
    }

    .three-tier .report {
        margin-top: 0px;
        margin-left: 5px;
    }

    .three-tier .left-nav {
        background-color: #f7f7f7;
        width: 290px;
        float: left !important;
        border-right: 1px solid #05050500;
        height: 100%;
        overflow-y: overlay;
    }

        .three-tier .left-nav .section-header {
            cursor: pointer;
            padding: 15px 10px 10px 15px;
            padding-top: 10px;
        }

            .three-tier .left-nav .section-header .active {
                background-color: #f2f2f2;
            }

            .three-tier .left-nav .section-header img {
                height: 14px !important;
                width: 21px !important;
            }

        .three-tier .left-nav .section-item {
            padding: 8px 8px 8px 50px;
            background-color: #f7f7f7;
            cursor: pointer;
        }

            .three-tier .left-nav .section-item:hover {
                color: #292827;
                font-weight: bold;
            }

        .three-tier .left-nav .active {
            background-color: #edebe9 !important;
            font-weight: bold;
            color: #292827;
        }

    .three-tier .content {
        background-color: white;
        height: calc(100% - 177px);
    }

    .three-tier .right-space {
        background-color: white;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }

.breadcumb-container {
    background-color: #185A5C;
    height: auto;
    padding: 5px 0px 5px 20px;
}

@media only screen and (max-width: 600px) {
    .breadcumb-container {
        position: fixed !important;
        z-index: 2000;
        width: 98%;
        font-size: 10px;
    }
}

@media only screen and (max-width: 425px) {
    .breadcumb-container {
        width: 96.8%;
    }
}

.subheader {
    width: 100%;
    background-color: #b9d1bc;
    height: auto;
    padding: 20px;
}

    .subheader .info-container-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
    }


    .subheader .top-left-text {
        font-size: 28px;
        color: black;
        font-weight: 600;
        margin: 0;
    }

    .subheader .bottom-left-text {
        font-size: 14px;
        color: black;
        font-weight: 200;
        margin: 0;
    }

@media only screen and (max-width: 600px) {
    .subheader .top-left-text {
        font-size: 22px;
        text-align: center;
    }


    .subheader .bottom-left-text {
        font-size: 13px;
    }

    .subheader .info-container-bottom {
        font-size: 13px;
    }

    .subheader-title {
        margin-top: 40px !important;
    }


    .subheader-container {
        margin-top: 1.2rem;
    }
}

.three-tier .content .content-inner {
    margin-bottom: 50px;
    background-color: white;
    border-right: 1px solid #eee;
    padding: 1px;
    min-height: unset;
    overflow: overlay;
    max-width: 100%;
}

.three-tier .content select {
    width: 200px;
}

.content .filter {
    float: left;
    padding-right: 10px;
}

.three-tier .content .group-tabs {
    margin-top: 20px !important;
    margin-left: 5px !important;
}

.three-tier .content .group-links {
    float: right;
    margin-top: 12px;
    margin-right: 20px;
}

    .three-tier .content .group-links span {
        margin-right: 20px;
        cursor: pointer;
    }

        .three-tier .content .group-links span:hover {
            border-bottom: 1px solid black;
            color: darkblue;
            font-weight: bold;
        }

        .three-tier .content .group-links span.active {
            border-bottom: 1px solid black;
            color: darkblue;
            font-weight: bold;
        }

.three-tier .footer {
    background-color: white;
    padding: 10px;
    overflow: hidden;
    text-align: center;
}

/* Dossier Styles */
.title-header {
    background: #EEE !important;
}

    .title-header[aria-label="title"] {
        background: #FFF !important;
    }

.mstrd-FilterSummaryBarItem-title {
    font-size: 12px !important;
}

.mstrd-FilterSummaryBarItem-summaryText {
    font-size: 12px !important;
}

.mstrd-FilterSummaryBar-filterCount {
    font-size: 12px !important;
}

.mstrd-FilterSummaryBar {
    height: 28px !important;
}

.mstrd-FilterSummary-barContainer {
    height: 28px !important;
}


.myFrame iframe {
}

.myFrame {
    display: block;
    height: calc(100vh - 150px);
}

.three-tier-open iframe {
    left: -10px !important;
}

.pagination-link-button {
    border: 0;
    background-color: white;
}

.pagination-link {
    padding-right: 10px;
    cursor: pointer !important;
    font-size: 14px !important;
    color: #2577B2;
}

.pagination-link-active {
    color: black !important;
}

.report-description {
    padding: 15px;
}

.report-description-link {
    cursor: pointer !important;
}

.report-footer {
    font-size: 10pt;
}
